<template>
    <div :class="theme">
        <HeaderComponent @toggle-mode="onToggleMode" />

        <div class="hero">
            <img :src="require(isDarkMode == true ? '@/assets/textlogo-white.png' : '@/assets/textlogo-black.png')"
                height="20" alt="" class="logo">
            <span class="paragraph">Dive into a world of 500 unique, decentralized pixel rock art pieces, each fully
                on-chain! This project stands out for its straightforward approach to pixel art, celebrating the essence of
                minimalism and the strength of decentralized ownership. with OnchainRocks, it's all about the art – no extra
                promises, no hype, and no roadmap. just pure pixel art, embracing scarcity and the revolutionary spirit of
                the blockchain.</span>
        </div>
        <RocksPreviewComponent />
        <StatsComponent />
        <RocksComponent />
    </div>
</template>
  
<script>
//import axios from "axios"; // <div ref="sentinel" style="height: 1px; margin-top: -1px;"></div>
import HeaderComponent from '../components/HeaderComponent.vue';
import RocksPreviewComponent from '../components/RocksPreviewComponent.vue';
import RocksComponent from '../components/RocksComponent.vue';
import StatsComponent from '../components/StatsComponent.vue';

export default {
    name: "HomeView",
    components: {
        HeaderComponent,
        RocksPreviewComponent,
        RocksComponent,
        StatsComponent
    },
    data() {
        return {
            theme: localStorage.getItem("isDarkMode") === "true" ? "dark-mode container" : "container",
            isDarkMode: localStorage.getItem("isDarkMode") === "true",
        };
    },
    methods: {
        onToggleMode() {
            this.theme = localStorage.getItem("isDarkMode") === "true" ? "dark-mode container" : "container";
            this.isDarkMode = localStorage.getItem("isDarkMode") === "true";
        }
    }
};
</script>
  
<style>
.header {
    width: 70%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container {
    padding: 50px;
    width: calc(100% - 100px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background: #fefefe;
    color: #000;
}

.dark-mode {
    background: #000;
    color: #fff;
}

.header>.links>a {
    text-decoration: none;
    color: #000;
    font-family: "MEK-Mono";
    font-size: 1.7rem;
    margin-right: 25px;
}

.header>.toggleMode {
    font-family: "MEK-Mono";
    font-size: 1.4rem;
    cursor: pointer;
    width: 50px;
}

.line {
    width: 70%;
    height: 1px;
    margin: 25px auto;
    background: #000;
    position: relative;
}

.line:before {
    content: '';
    width: 1px;
    height: 10px;
    background: inherit;
    position: absolute;
    top: 0;
    left: 0;
}

.line:after {
    content: '';
    width: 1px;
    height: 10px;
    background: inherit;
    position: absolute;
    top: 0;
    right: 0;
}

.dark-mode>.header>.links>a {
    color: #fff;
}

.dark-mode>.line {
    background: #fff;
}

.hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 200px auto;
    text-align: center;
}

.hero>.logo {
    margin-bottom: 15px;
}

.items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: -1px;
}

/* Style for grid items */
.items>.item {
    border: 0.5px solid #dfdfdf;
    display: flex;
    flex-direction: column;
    transition: border 100ms;
    text-decoration: none;
    color: #000;
    background: #fff;
}

.items>.item>.p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;

}

.items>.item>.p>a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.items>.item>.p>.id {
    font-family: "MEK-Mono";
    font-size: 1.25rem;
}

.items>.item>img {
    width: 100%;
    /* Make the image fill the container */
    height: auto;
    /* Maintain the aspect ratio */
    display: block;
    /* Remove any extra spacing */
    border-bottom: 0.5px solid #dfdfdf;
}

/* Responsive styling for smaller screens */
@media screen and (max-width: 768px) {

    .stats-items,
    .header,
    .line {
        width: 100% !important;
        /* grid-template-columns: 1fr;*/
        /* Single column for smaller screens */
    }

    .rocks-items {
        width: 100% !important;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
}

@media screen and (max-width:500px) {
    .container {
        width: 100%;
        padding: 25px;
    }

    .header {
        flex-direction: column;
    }

    .links {
        margin: 20px 0;
    }

    .hero {
        width: 80%;
        margin: 100px auto;
    }
    .stats-items {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)) !important;
        /* grid-template-columns: 1fr;*/
        /* Single column for smaller screens */
    }

}</style>