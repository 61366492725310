<template>
  <img :src="require('@/assets/image.png')" style="border:1px solid #dfdfdf;margin:0 auto; width:90%;">
</template>

<script>
export default {
  name: 'RocksPreviewComponent',
  data() {
    return {
      isDarkMode: localStorage.getItem("isDarkMode") === "true",
    };
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>