<template>
  <div class="header">
    <a href="/home"><img :src="require(isDarkMode == true ? '@/assets/white.png' : '@/assets/black.png')" width="50" alt="" class="logo"></a>
    <div class="links">
      <a href="https://opensea.io/collection/onchainrocks">Opensea</a>
      <a href="">About</a>
      <a href="https://twitter.com/onchainrocks">Social</a>
    </div>
    <span class="toggleMode" @click="toggleMode">{{ isDarkMode === true ? "Light" : "Dark" }}</span>
  </div>
  <span class="line"></span>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data() {
    return {
      isDarkMode: localStorage.getItem("isDarkMode") === "true",
    };
  },
  methods: {
    toggleMode() {
      this.isDarkMode = !this.isDarkMode;
      localStorage.setItem('isDarkMode', this.isDarkMode);
      this.$emit('toggle-mode');
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>