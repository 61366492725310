<template>
  <h1 style="margin:25px auto;margin-top:100px;">Overall Stats</h1>
  <div class="items stats-items" style="width:70%;margin:25px auto;margin-bottom:100px;grid-template-columns: repeat(3, minmax(150px, 1fr));gap:15px;">
    <div v-for="(item, index) in stats" :key="index" style="background:none; border:none;display:flex;flex-direction:column;">
      <span style="opacity:0.4;text-transform: uppercase;">{{ item.name }}</span>
      <span style="font-weight:700;font-size:1.25rem;">{{ (index == 0 || index == 2 || index == 3 || index == 4) ? item.value.toString().substring(0, 5) + " ETH" : item.value }}</span>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: 'StatsComponent',
  data() {
    return {
      isDarkMode: localStorage.getItem("isDarkMode") === "true",
      stats: []
    };
  },
  methods: {
    async getStats() {
      const response = await axios.get(
        `https://api.opensea.io/api/v2/collections/onchainrocks/stats`,
        {
          params: {
            collectionSlug: "onchainrocks"
          },
          headers: { accept: 'application/json', 'x-api-key': '433266b9f4cd46b2af1ef7e59e009d41' }
        }
      );

      this.stats = {
        0: { name: "Total Volume", value: response.data.total.volume },
        1: { name: "Total Sales", value: response.data.total.sales },
        2: { name: "Total Market Cap", value: response.data.total.market_cap },
        3: { name: "Average Price", value: response.data.total.average_price },
        4: { name: "Floor Price", value: response.data.total.floor_price },
        5: { name: "Number of Owners", value: response.data.total.num_owners }
      };
    }
  },
  mounted() {
    this.getStats()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>