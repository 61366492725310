<template>
    <div :class="theme">
        <HeaderComponent @toggle-mode="onToggleMode" />
        <div class="rock">
            <img v-if="rock.image" :src="rock.image.cachedUrl" alt="">
            <div class="props">
                <h1>#{{ rock.tokenId }}</h1>
                <h2 style="opacity: 0.5;">{{ rock.description }}</h2>
                <div v-if="rock.raw" class="traits">
                    <div v-for="trait in rock.raw.metadata.attributes" :key="trait.value" class="trait">
                        <span class="trait-head">{{ trait.trait_type }}</span>
                        <span class="trait-value">{{ trait.value }}</span>
                    </div>
                </div>
                <div v-if="owner" class="owner">
                    <span class="owner-head">owner</span>
                    <a :href="`https://etherscan.io/address/${owner}`" class="owner-value">{{ owner.substring(0, 10) + "..."
                        + owner.slice(-10) }}</a>
                </div>
                <a :href="`https://opensea.io/assets/ethereum/0xc51d4269d159beb8a91ef9f0a8da9c40443d6bd4/${rock.tokenId}`"
                    class="opensea-link">View on OpenSea</a>
            </div>
        </div>
    </div>
</template>
<style scoped>
.container {
    height: 100vh;
}

.owner {
    width: 100%;
    padding: 25px;
    border: 1px solid #dfdfdf;
    display: flex;
    flex-direction: column;
    text-align: center;
    box-sizing: border-box;
}

.owner-head {
    opacity: 0.2;
    text-transform: uppercase;
    font-weight: 700;
}

.owner-value {
    font-weight: 600;
    font-size: 1.4rem;
    color: #000
}

.opensea-link {
    font-weight: 600;
    font-size: 1.4rem;
    text-decoration: none;
    background: #000;
    color: #fff;
    padding: 25px;
    width: 100%;
    margin: 25px auto;
    box-sizing: border-box;
    text-align: center;
}

.dark-mode .opensea-link {
    background-color: #fff;
    color: #000;
}

.dark-mode .owner-value {
    color: #fff;
}

.traits {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
}

.traits>.trait {
    border: 1px solid #dfdfdf;
    padding: 25px;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.traits>.trait>.trait-head {
    opacity: 0.2;
    text-transform: uppercase;
    font-weight: 700;
}

.traits>.trait>.trait-value {
    font-weight: 600;
    font-size: 1.4rem;
}

.rock {
    display: flex;
    width: 70%;
    margin: 50px auto;
    justify-content: space-between;
}

.rock>img {
    width: 40%;
    border: 1px solid #dfdfdf;
}

.rock>.props {
    display: flex;
    flex-direction: column;
    width: 55%;
}

@media only screen and (max-width:1000px) {
    .rock {
        flex-direction: column;
    }

    .rock>img {
        width: 70%;
        margin: 25px auto;
    }

    .rock>.props {
        width: 70%;
        margin: 0 auto;
    }

    .container {
        height: 150vh;
    }
}

@media only screen and (max-width:768px) {
    .rock {
        flex-direction: column;
    }

    .rock>img {
        width: 100%;
        margin: 25px auto;
    }

    .rock>.props {
        width: 100%;
        margin: 0 auto;
    }
}
</style>
<script>
import axios from "axios";
import HeaderComponent from '../components/HeaderComponent.vue';

export default {
    name: "RockView",
    components: {
        HeaderComponent,
    },
    data() {
        return {
            theme: localStorage.getItem("isDarkMode") === "true" ? "dark-mode container" : "container",
            rock: {},
            owner: "",
            isDarkMode: localStorage.getItem("isDarkMode") === "true",
        };
    },
    methods: {
        onToggleMode() {
            this.theme = localStorage.getItem("isDarkMode") === "true" ? "dark-mode container" : "container";
        },
        async getRock() {
            const response = await axios.get(
                `https://eth-mainnet.g.alchemy.com/nft/v3/HKopazsGdtv9xtSEqrf78W649_5lrhQQ/getNFTMetadata`,
                {
                    params: {
                        contractAddress: "0xc51d4269d159beb8a91ef9f0a8da9c40443d6bd4",
                        tokenId: this.$route.params.id,
                        refreshCache: false
                    },
                }
            );
            this.rock = response.data;
            if (this.rock.tokenId == null || this.rock.tokenId > 500 || this.rock.tokenId <= 0) {
                this.$router.push(`/home`);
            }
        },
        async getOwner() {

            const response = await axios.get(
                `https://api.opensea.io/api/v2/chain/ethereum/contract/0xc51d4269d159beb8a91ef9f0a8da9c40443d6bd4/nfts/${this.$route.params.id}`,
                {
                    headers: { accept: 'application/json', 'x-api-key': '433266b9f4cd46b2af1ef7e59e009d41' }
                }
            );

            this.owner = response.data.nft.owners[0].address;
        }
    },
    mounted() {
        this.getRock()
        this.getOwner()
    }
};
</script>
  
<style scoped></style>
  