<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {

  data() {
    return {
      theme: localStorage.getItem("isDarkMode") === "true" ? "dark-mode container" : "container",
      isDarkMode: localStorage.getItem("isDarkMode") === "true",
    };
  },
  methods: {
    onToggleMode() {
      this.theme = localStorage.getItem("isDarkMode") === "true" ? "dark-mode container" : "container";
      this.isDarkMode = localStorage.getItem("isDarkMode") === "true";
    }
  }
};
</script>

<style>
</style>