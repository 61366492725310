<template>
  <div class="comp-header" style="width:70%; margin:25px auto; display:flex; justify-content: space-between; align-items: center;">
    <h1>All Rocks (500)</h1>

    <div class="form">
      <input type="number" placeholder="Rock Id" v-model="searchId" style="border:1px solid #dfdfdf;padding:10px;">
      <button @click="searchById">Go</button>
    </div>
  </div>
  <div class="items rocks-items" style="width:70%;margin:0 auto;" ref="itemsContainer">
    <a v-for="item in items" :key="item.id" :href="`/rock/${item.tokenId}`" class="item">
      <img :src="item.image.cachedUrl" alt="">
      <div class="p">
        <span class="id">{{ item.tokenId }}</span>
        <a :href="`https://opensea.io/assets/ethereum/0xc51d4269d159beb8a91ef9f0a8da9c40443d6bd4/${item.tokenId}`">
          <svg xmlns="http://www.w3.org/2000/svg" style="opacity:0.4;" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M0.154707 12.5961C0.106378 5.92664 5.54341 0.489603 12.2128 0.537932C18.7131 0.586261 24.0293 5.90247 24.0777 12.4028C24.1502 19.0722 18.7131 24.5092 12.0195 24.4609C5.51925 24.3884 0.203037 19.0963 0.154707 12.5961ZM20.8155 14.6742C20.8638 14.6501 20.888 14.6017 20.888 14.5534L20.8638 13.7076C20.8638 13.6351 20.7671 13.5626 20.6946 13.5868C20.6946 13.5868 16.7316 14.4809 16.6833 14.5051C16.635 14.5292 16.6108 14.5534 16.6108 14.5534C16.2 15.085 15.3543 15.9791 14.8951 15.9791H12.7203V14.5534H14.4602C14.7018 14.5534 14.9435 14.4567 15.1126 14.2876C16.0067 13.3693 16.5142 12.2578 16.5142 11.0737C16.5142 9.04387 14.9918 7.25569 12.6962 6.19245V5.22586C12.6962 4.81506 12.3578 4.47676 11.9471 4.47676C11.5363 4.47676 11.1979 4.81506 11.1979 5.22586V5.63666C10.5697 5.44334 9.89306 5.29836 9.16812 5.22586C10.3522 6.50659 11.0771 8.22227 11.0771 10.1071C11.0771 11.7986 10.4972 13.3452 9.53059 14.5776H11.2221V16.0033H8.75732C8.41902 16.0033 8.15321 15.7375 8.15321 15.3992V14.7709C8.15321 14.6742 8.08071 14.5776 7.95989 14.5776H4.72184C4.64934 14.5776 4.60101 14.6259 4.60101 14.6984C4.57685 17.2598 6.60667 19.193 9.0473 19.193H15.9342C17.1716 19.193 17.8883 17.9871 18.5796 16.824C18.8063 16.4425 19.0302 16.0657 19.2689 15.7375C19.6556 15.2058 20.5496 14.795 20.8155 14.6742ZM9.82057 10.1071C9.82057 8.80222 9.38561 7.61816 8.6365 6.67574L4.91516 13.1277H8.92648C9.48227 12.2578 9.82057 11.2187 9.82057 10.1071Z"
              fill="#111111" />
          </svg>
        </a>
      </div>
    </a>
  </div>
  <div ref="sentinel" style="height: 1px; margin-top: -1px;"></div>
</template>
<style>
.form >input:focus {
  outline: none;
}

.dark-mode .form >button {
  border:1px solid #fff;
  background:#fff;
  color:#000;
}
.form >button {
  border:1px solid #000;
  background:#000;
  color:#fff;
  cursor:pointer;
  font-weight: 500;
  padding:10px;
}

@media only screen and (max-width:768px) {
  .comp-header {
    width:100% !important;
  }
}
@media only screen and (max-width:600px) {
  .comp-header {
    width:100% !important;
    flex-direction: column;
  }
}
</style>
<script>
import axios from "axios"

export default {
  name: 'RocksComponent',
  data() {
    return {
      isDarkMode: localStorage.getItem("isDarkMode") === "true",
      items: [],
      currentPage: 1,
      itemsPerPage: 16,
      isLoading: false,
      totalItems: 500,
    };
  },
  methods: {
    async fetchItems() {
        try {
            this.isLoading = true;

            const response = await axios.get(
                `https://eth-mainnet.g.alchemy.com/nft/v3/HKopazsGdtv9xtSEqrf78W649_5lrhQQ/getNFTsForCollection`,
                {
                    params: {
                        collectionSlug: "onchainrocks",
                        withMetadata: true,
                        startToken: (this.currentPage - 1) * this.itemsPerPage,
                        limit: this.itemsPerPage
                    }
                }
            );

            this.items = [...this.items, ...response.data.nfts];
            this.currentPage++;
        } catch (error) {
            console.error("Error fetching items:", error);
        } finally {
            this.isLoading = false;
        }
    },
    handleIntersection(entries) {
        const [entry] = entries;
        if (
            entry.isIntersecting &&
            !this.isLoading &&
            this.items.length < this.totalItems
        ) {
            this.fetchItems();
        }
    },
    async searchById() {
        if (this.searchId != null && this.searchId <= 500 && 0 <= this.searchId) {
            this.$router.push(`/rock/${this.searchId}`);
        }
    },/*
    async getFloor() {
        const response = await axios.get(
            `https://eth-mainnet.g.alchemy.com/nft/v3/HKopazsGdtv9xtSEqrf78W649_5lrhQQ/getCollectionMetadata`,
            {
                params: {
                    collectionSlug: "onchainrocks"
                }
            }
        );

        this.floor = response.data.floorPrice.floorPrice;
    }*/
  },
  mounted() {
    this.fetchItems();

    const observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1
    });

    observer.observe(this.$refs.sentinel);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>